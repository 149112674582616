import { css } from '@emotion/react'
import { createFilterOptions, type FilterOptionsState } from '@mui/material'
import type { GroupData } from '@orus.eu/backend/src/views/activity-grouping-view'
import { colorTokens, ProductBadge, SearchBar, spacing } from '@orus.eu/pharaoh'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { useState } from 'react'

const defaultFilterOptions = createFilterOptions<GroupData>()
const filterOptions = (options: GroupData[], state: FilterOptionsState<GroupData>) => {
  return defaultFilterOptions(options, state).slice(0, 20)
}

export function ActivitySearchField({
  searchOptions,
  onChange,
  disabled,
  helperText,
}: {
  searchOptions: GroupData[]
  onChange: (group: GroupData) => void
  disabled?: boolean
  helperText?: string
}): React.ReactNode {
  const [inputValue, setInputValue] = useState('')

  return (
    <SearchBar
      size="small"
      options={searchOptions}
      placeholder="Exemple : restaurant, psychologue, développeur..."
      helperText={helperText}
      freeSolo={false}
      value={null}
      onChange={(_event, group) => {
        setInputValue('')
        if (group) onChange(group)
      }}
      hasCustomEndAdornment={true}
      disabled={disabled}
      getOptionLabel={(group: GroupData) => group.name}
      filterOptions={filterOptions}
      inputValue={inputValue}
      onInputChange={(_event, newValue) => setInputValue(newValue)}
      blurOnSelect={true}
      clearInputValue={() => setInputValue('')}
      renderOption={(props, group, { inputValue }) => {
        const { key, ...restProps } = props
        const groupName = group.name
        const matches = match(groupName, inputValue, { insideWords: true })
        const parts = parse(groupName, matches)

        return (
          <li
            key={key}
            {...restProps}
            css={css`
              display: flex;
              gap: ${spacing[20]};
            `}
          >
            <div
              css={css`
                flex-grow: 1;
              `}
            >
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    color: part.highlight ? colorTokens['color-text-base-link'] : undefined,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
            {group.products.map((product) => (
              <ProductBadge key={product} product={product} />
            ))}
          </li>
        )
      }}
    />
  )
}
